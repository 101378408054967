var site = site || {};
var generic = generic || {};

(function ($, Drupal) {
  var videoBehavior = {
    setup: function ($modules, context) {
      var animationTime = 1200;

      $modules.each(function () {
        init($(this));
      });

      function init($module) {
        var $play = $('.js-play', $module);
        var $hide = $('.js-video-hide', $module);
        var $reveal = $('.js-video-reveal', $module);
        var provider = $module.data('video-provider');
        var isYoutube = provider === 'youtube';
        var isZentrick = provider === 'zentrick';
        var isHTML5 = provider === 'html5';

        $play.on('click', function (event) {
          event.preventDefault();

          // Pass options with overlay / where to insert video content if not overlay
          var opts = {
            context: context,
            provider: provider,
            className: 'overlay--content-block-video',
            openOverlay: $play.hasClass('js-play--overlay'),
            $content: $reveal
          };

          // Triggered once video is loaded via JS and ready to show
          $reveal.off('video.loaded').on('video.loaded', function (event) {
            $hide.fadeTo(1000, 0);
            $reveal.fadeIn(animationTime);
          });

          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          } else if (!!isZentrick) {
            opts.zentrickId = $module.data('zentrick-id');
          } else if (!!isHTML5) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          }

          site.videos.open(opts);

          return false;
        });
      }
    }
  };

  /**
   * We initialize each type of video separately because we don't know if/when their respective libraries are loaded
   */

  $(document).on('youtubeIframeAPI.loaded', function () {
    var $elements = $('.js-video-block--v1').filter("[data-video-provider='youtube']");
    var enable_spp_locale_video = Drupal.settings.globals_variables.enable_spp_locale_video;

    if (enable_spp_locale_video) {
      var selected_locale = generic.cookie('LOCALE');
      var localeParts = selected_locale.toLowerCase().split('_');
      var youtube_id_key = 'youtube-id-' + localeParts[1];

      $elements.each(function () {
        var local_youtube_id = $(this).data(youtube_id_key);

        if (local_youtube_id) {
          $(this).attr('data-youtube-id', local_youtube_id);
        }
      });
    }
    if ($elements.length) {
      videoBehavior.setup($elements, document);
    }
  });

  $(document).on('zentrick:loaded', function () {
    var $elements = $('.js-video-block--v1').filter("[data-video-provider='zentrick']");

    if ($elements.length) {
      videoBehavior.setup($elements, document);
    }
  });

  Drupal.behaviors.videoBlockV1 = {
    attach: function (context, settings) {
      var $elements = $('.js-video-block--v1').filter("[data-video-provider='html5']");

      if ($elements.length) {
        videoBehavior.setup($elements, context);
      }
    }
  };
})(jQuery, Drupal);
